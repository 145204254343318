<template>
  <!-- Vue réduite (affichage en grille) -->
  <b-card
    class="lettre-card"
    :title="item.attributes.titre"
    :img-src="couvertureSrc"
    :img-alt="couvertureAlt"
    img-top
  >
    <template #header>
      <p class="lettre-categorie" :style="cssVars">
        {{ categorie }}
      </p>
    </template>
    <b-card-text>
      <em>Publi&eacute;e le {{ datePublication }}</em>
    </b-card-text>
    <b-card-text class="text-justify">
      {{ item.attributes.description }}
    </b-card-text>
    <template #footer>
      <b-link :href="lettreHref" target="_blank" @click.stop>
        <b-button pill variant="primary">Pi&egrave;ce-jointe</b-button>
      </b-link>
    </template>
  </b-card>
</template>

<script>
import StrapiService from "../../services/strapi.service";
export default {
  name: "StrapiLettreTechniqueComponent",
  props: ["item"],
  computed: {
    couvertureSrc() {
      return StrapiService.getImageUrl(
        this.item.attributes.couverture,
        "small"
      );
    },
    couvertureAlt() {
      return (
        this.item.attributes.couverture?.data?.attributes.alternativeText || ""
      );
    },
    datePublication() {
      return new Date(this.item.attributes.publishedAt).toLocaleDateString();
    },
    categorie() {
      return this.item.attributes.categorie?.data?.attributes.libelle || "";
    },
    lettreHref() {
      return StrapiService.getAssetUrl(
        this.item.attributes.fichier?.data?.attributes.url
      );
    },
    cssVars() {
      return {
        "--back-color":
          this.item.attributes.categorie?.data?.attributes.backcolor,
        "--front-color":
          this.item.attributes.categorie?.data?.attributes.frontcolor,
      };
    },
  },
};
</script>
