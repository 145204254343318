<template>
  <b-row>
    <b-col v-for="item in items" :key="item.id" cols="auto">
      <StrapiLettreTechniqueComponent :item="item">
      </StrapiLettreTechniqueComponent>
    </b-col>
  </b-row>
</template>

<script>
import StrapiLettreTechniqueComponent from "./StrapiLettreTechniqueComponent.vue";
import StrapiService from "../../services/strapi.service";
import UtilsService, { SortDirection } from "../../services/utils.service";
export default {
  name: "StrapiCategorieLettreTechniqueComponent",
  props: ["item"],
  components: {
    StrapiLettreTechniqueComponent,
  },
  data() {
    return {
      items: [],
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      if (!this.item?.data?.id) {
        return;
      }
      let response = await StrapiService.getLettresTechniques(
        this.item.data.id
      );
      this.items = response.data.data.sort(
        UtilsService.sortBy(
          UtilsService.sortByNumberProperty(
            "attributes.ordre",
            SortDirection.ASC
          ),
          UtilsService.sortByStringProperty(
            "attributes.publishedAt",
            SortDirection.DESC
          )
        )
      );
    },
  },
};
</script>
